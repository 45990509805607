import React, { useEffect, useState } from "react";
import { 
  Avatar,
  Box,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  fetchUser,
  fetchUserSessions,
} from "../shared/services"
import { FBSession, FBUser } from "../shared/types";
import { HOOPER_BLUE } from "../shared/constants";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SessionsTable from "./SessionsTable";
import LoadingView from "./LoadingView";

/**
 * UserView - Contains actions and limited data for a single user.
 */
const UserView = () => {
  const params = useParams();
  const userId = params.id;

  const [loading, setLoading] = useState<boolean>(true);
  const [sessions, setSessions] = useState<FBSession[]>([]);
  const [user, setUser] = useState<FBUser | undefined>();

  useEffect(() => {
    if (userId) {
      handleOnFetchData(userId);
    }
  }, [userId]);

  const handleOnFetchData = async (userId: string) => {
    try {
      const [
        user,
        sessions,
      ] = await Promise.all([
        fetchUser(userId),
        fetchUserSessions(userId, 0, 100),
      ]);
      if (user) setUser(user);
      if (sessions) setSessions(sessions);
    } catch (e) {
      console.error('Error in `UserView.handleOnFetchData`: ', e);
    } finally {
      setLoading(false);
    }
  };
  
  const getUserContent = () => {
    if (!user) return null;
    return (
      <Grid 
        container
        spacing={8}
      >
        <Grid item>
          <Box>
            <Avatar 
              src={user?.profilePicture} 
              sx={{
                width: 120, 
                height: 120, 
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Stack
              direction="row"
              spacing={2}
            >
              <Typography variant="h5" gutterBottom>
                {user.username || "--"}
              </Typography>
              <Chip 
                label={user.subscriptionType} 
                sx={{ 
                  backgroundColor: HOOPER_BLUE,
                  color: "white",
                }}
              />
            </Stack>
            <Typography variant="body1">
              {user.email || "--"}
            </Typography>
            <Typography variant="body1" sx={{ color: "gray" }}>
              ID: {user.userId}
            </Typography>
            <Typography variant="body1" sx={{ color: "gray" }}>
              Joined {dayjs.unix(user.createdAt).format("MM/D/YY")}, Updated {dayjs.unix(user.updatedAt).format("MM/D/YY")}
            </Typography>
            <Grid 
              container
              spacing={4}
              sx={{ pt: 2}}
            >
              <Grid item>
                <CameraAltIcon 
                  style={{color: user.cameraPermission ? HOOPER_BLUE : "gray"}}
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Grid>
              <Grid item>
                <LocationOnIcon
                  style={{color: user.locationPermission ? HOOPER_BLUE : "gray"}}
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardVoiceIcon
                  style={{color: user.microphonePermission ? HOOPER_BLUE : "gray"}}
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Grid>
              <Grid item>
                <NotificationsIcon
                  style={{color: user.pushPermission ? HOOPER_BLUE : "gray"}}
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }

  const getContent = () => {
    return (
      <Box sx={{ pt: 2, pb: 4 }}>
        <Box sx={{ mx: 8, my: 4 }}>
          {getUserContent()}
        </Box>
        <Box sx={{ mx: 8, my: 4 }}>
          <Typography 
            variant="h6" 
            noWrap 
            component="div" 
            sx={{ 
              fontWeight: 'bold',
              mb: 2,
              color: HOOPER_BLUE,
            }}
          >
            User Sessions
          </Typography>
          <SessionsTable sessions={sessions} />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {loading ? <LoadingView loading={loading} /> : getContent()}
    </Box>
  );
};

export default UserView;