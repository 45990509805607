import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import type { 
  EditShotV4Request,
  EditTagV4Request,
  EditHighlightV4Request,
  EditClusterTagV4Request,
  FBClusterTagV4,
  FBShotV4,
  FBHighlightV4,
  FBTagV4,
  FBKeyframeV4,
  FBSession,
  TagShotHighlight,
  AnnotatedPaint,
  AnnotatedHoopPoint,
  AnnotatedHasNet,
} from "../shared/types";
import { Checkbox, Divider } from "@mui/material";
import { 
  deleteSession, 
  fetchSessionV4, 
  setSessionStatus,
  addPause,
  deletePause,
  requestVideoCompression,
  updateVideoUrl,
  editShotV4,
  editHighlightV4,
  editClusterTagV4,
  editTagV4,
  inferVideoV4,
  inferVideoV3,
  inferKeyframesV3,
  setSessionNetPresencesV4,
  setSessionHoopPointsV4,
  setSessionPaintKeypointsV4,
} from "../shared/services";
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyframeV4 from "./KeyframeV4";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingView from "./LoadingView";
import { 
  HOOPER_LIGHT_BLUE,
  HOOPER_RED,
  HOOPER_GREEN,
  HOOPER_YELLOW,
  HOOPER_BLUE, 
  SessionStatus,
} from "../shared/constants";
import { 
  formatAnnotatedHoopPoints,
  formatAnnotatedNetPresence,
  formatAnnotatedPaints,
} from "../shared/utils";

/**
 * Is make (version 4)
 * @param shot (FBShotV4)
 * @returns (boolean)
 */
export const isShotIn = (shot: FBShotV4): boolean => {
  if ((shot.annotatedShotOutcome !== undefined) && (shot.annotatedShotOutcome !== null)) {
    return shot.annotatedShotOutcome;
  }
  if ((shot.shotOutcome !== undefined) && (shot.shotOutcome !== null)) {
    return shot.shotOutcome;
  }
  return false;
}

/**
 * SessionViewV4 - Contains actions and limited data for a single session.
 */
const SessionViewV4 = () => {
  const params = useParams();
  const sessionId = params.id;
  const navigate = useNavigate();
  // State variables
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<FBSession>();
  const [highlights, setHighlights] = useState<FBHighlightV4[]>([]);
  const [shots, setShots] = useState<FBShotV4[]>([]);
  const [tags, setTags] = useState<FBTagV4[]>([]);
  const [clusters, setClusters] = useState<FBClusterTagV4[]>([]);
  const [keyframes, setKeyframes] = useState<FBKeyframeV4[]>([]);
  const [hoops, setHoops] = useState<AnnotatedHoopPoint[]>([]);
  const [paints, setPaints] = useState<AnnotatedPaint[]>([]);
  const [hasNets, setHasNets] = useState<AnnotatedHasNet[]>([]);
  const [selectedKeyframe, setSelectedKeyframe] = useState<FBKeyframeV4>();
  const [selectedHoop, setSelectedHoop] = useState<AnnotatedHoopPoint>();
  const [selectedPaint, setSelectedPaint] = useState<AnnotatedPaint>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // State variables for forms
  const [deleteSessionAttempt, setDeleteSessionAttempt] = useState<boolean>(false);
  const [selectedRunVersion, setSelectedRunVersion] = useState<number>();
  const [newVideoUrl, setNewVideoUrl] = useState<string>();
  const [newStatus, setNewStatus] = useState<SessionStatus>();
  // Reference for full video
  const fullVideoRef = useRef<HTMLVideoElement>(null);
  const [fullVideoCurrentTime, setFullVideoCurrentTime] = useState<number>(0);
  const [fullVideoDuration, setFullVideoDuration] = useState<number>(0);
  const isFullVideoRefUsed = !!fullVideoRef.current;
  /** 
   * Hook to fetch new data on change of session ID 
   */
  useEffect(() => {
    if (!sessionId) return;
    handleOnFetchData(sessionId)
    .then(() => setLoading(false))
    .catch((err) => console.error(`Error in 'SessionViewV4.useEffect': ${err}`))
  }, [sessionId]);

  /**
   * Fetch data for a session and set state variables
   * @param sessionId (string): ID of the session to fetch
   */
  const handleOnFetchData = async (sessionId: string): Promise<FBSession | undefined> => {
    let output: FBSession | undefined;
    try {
      const raw = await fetchSessionV4(sessionId);
      if (raw) {
        if (raw.session) {
          setSession(raw.session);
          setSelectedRunVersion(raw.runVersion);
          setNewStatus(raw.session.status as SessionStatus);
          setHoops(formatAnnotatedHoopPoints(raw.session.annotatedHoopPoints || []));
          setPaints(formatAnnotatedPaints(raw.session.annotatedPaintKeypoints || []));
          setHasNets(formatAnnotatedNetPresence(raw.session.overrideNetPresences || []));
          output = raw.session;
        }
        if (raw.highlights) setHighlights(raw.highlights);
        if (raw.shots) setShots(raw.shots);
        if (raw.tags) setTags(raw.tags);
        if (raw.clusters) setClusters(raw.clusters);
        if (raw.keyframes) setKeyframes(raw.keyframes.sort((a, b) => a.ts - b.ts));
      }
    } catch (e) {
      const message = `Error in "fetchSession" or "fetchKeyframes": ${e}`;
      console.error(message);
      toast.error(message);
    }
    return output;
  }
  /**
   * Callback when editing a cluster.
   * @param cluster (FBClusterTagV4) - the cluster to edit
   */
  const onEditCluster = (cluster: FBClusterTagV4) => {
    let newClusters: FBClusterTagV4[] = [];
    for (let i = 0; i < clusters.length; i++) {
      if (clusters[i].clusterId === cluster.clusterId) {
        newClusters.push({...cluster});
      } else {
        newClusters.push(clusters[i]);
      }
    }
    setClusters([...newClusters]);
    // Send a post request to save the updated cluster
    if (cluster.clusterId) {
      const body: EditClusterTagV4Request = {
        clusterId: cluster.clusterId,
        taggerId: cluster.taggerId || "",
        teamId: cluster.teamId,  // already changed
        userId: cluster.userId,
        name: cluster.name,
        ignore: cluster.ignore || false,
      }
      editClusterTagV4(body).catch(
        err => console.error(`Failed to edit cluster tag ${cluster.clusterId}. Error: ${err}`)
      );
    }
  }
  /**
   * Callback to ignore a tag
   * @param ignore (boolean) - Whether to ignore the tag
   * @param cluster (FBClusterTagV4) - The cluster to ignore
   */
  const onIgnoreCluster = (ignore: boolean, cluster: FBClusterTagV4) => {
    onEditCluster({...cluster, ignore: ignore });
  }
  /**
   * Callback when editing a tag.
   * @param tag (FBTagV4) - the tag to edit
   */
  const onEditTag = (tag: FBTagV4) => {
    let newTags: FBTagV4[] = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].tagId === tag.tagId) {
        newTags.push({...tag});
      } else {
        newTags.push(tags[i]);
      }
    }
    setTags([...newTags]);
    // Send a post request to save the updated tag
    if (tag.tagId && tag.clusterId) {
      const body: EditTagV4Request = {
        tagId: tag.tagId, 
        clusterId: tag.clusterId, 
        ignore: tag.ignore || false,
      };
      editTagV4(body)
      .catch(err => console.error(`Failed to edit tag ${tag.tagId}. Error: ${err}`));
    }
  }
  /**
   * Callback when editing a shot.
   * @param shot (FBShotV4) - the shot to edit
   */
  const onEditShot = (shot: FBShotV4) => {
    let newShots: FBShotV4[] = [];
    for (let i = 0; i < shots.length; i++) {
      if (shots[i].shotId === shot.shotId) {
        newShots.push({...shot});
      } else {
        newShots.push(shots[i]);
      }
    }
    setShots([...newShots]);
    // Send a post request to save the updated shot
    if (shot.shotId) {
      const body: EditShotV4Request = { 
        shotId: shot.shotId, 
        shotOutcome: shot.annotatedShotOutcome,
        isTwoPoint: shot.annotatedIsTwoPoint,
        ignore: shot.ignore || false 
      };
      editShotV4(body)
      .catch(err => console.error(`Failed to edit shot ${shot.shotId}. Error: ${err}`));
    }
  }
  /**
   * Callback when editing a highlight.
   * @param highlight (FBHighlightV4) - the highlight to edit
   */
  const onEditHighlight = (highlight: FBHighlightV4) => {
    let newHighlights: FBHighlightV4[] = [];
    for (let i = 0; i < highlights.length; i++) {
      if (highlights[i].highlightId === highlight.highlightId) {
        newHighlights.push({...highlight});
      } else {
        newHighlights.push(highlights[i]);
      }
    }
    setHighlights([...newHighlights]);
    // Send a post request to save the updated highlight
    if (highlight.highlightId) {
      const body: EditHighlightV4Request = {
        highlightId: highlight.highlightId,
        showInFeed: highlight.showInFeed || true,
        isLowlight: highlight.isLowlight || false,
        visible: highlight.visible || 1,
      };
      editHighlightV4(body)
      .catch(err => console.error(`Failed to edit highlight ${highlight.highlightId}. Error: ${err}`));
    }
  }
  /**
   * Callback to ignore a tag
   * @param ignore (boolean) - Whether to ignore the tag
   * @param tag (FBTagV4) - The tag to ignore
   * @param highlight (FBHighlightV4) - The highlight to ignore
   */
  const onIgnoreTag = (ignore: boolean, tag: FBTagV4, highlight: FBHighlightV4) => {
    onEditTag({...tag, ignore: ignore });
    onEditHighlight({...highlight, visible: ignore ? 0 : 1 });
  }
  /**
   * Callback to ignore a shot
   * @param ignore (boolean) - Whether to ignore the tag
   * @param tag (FBTagV4) - The tag to ignore
   * @param shot (FBShotV4) - The shot to ignore
   * @param highlight (FBHighlightV4) - The highlight to ignore
   */
  const onIgnoreShot = (ignore: boolean, tag: FBTagV4, shot: FBShotV4, highlight: FBHighlightV4) => {
    onEditTag({...tag, ignore: ignore });
    onEditShot({...shot, ignore: ignore });
    onEditHighlight({...highlight, visible: ignore ? 0 : 1 });
  }
  /**
   * Callback to edit the shot outcome
   * @param outcome (boolean) - Whether the shot outcome is two point
   * @param shot (FBShotV4) - The shot to edit
   * @param highlight (FBHighlightV4) - The highlight to edit
   */
  const onEditShotOutcome = (outcome: boolean, shot: FBShotV4, highlight: FBHighlightV4) => {
    onEditShot({...shot, annotatedShotOutcome: outcome });
    onEditHighlight({...highlight, isLowlight: !outcome });
  }
  /**
   * Callback to edit the shot points
   * @param isTwoPoint (boolean) - Whether the shot is two point
   * @param shot (FBShotV4) - The shot to edit
   */
  const onEditShotPoints = (isTwoPoint: boolean, shot: FBShotV4) => {
    onEditShot({...shot, annotatedIsTwoPoint: isTwoPoint });
  }
  /**
   * Callback to hide highlight from feed
   */
  const onHideHighlight = (showInFeed: boolean, highlight: FBHighlightV4) => {
    onEditHighlight({...highlight, showInFeed: showInFeed });
  }
  /**
   * Callback to edit a hoop
   */
  const onEditHoops = (hoop: AnnotatedHoopPoint) => {
    let newHoops: AnnotatedHoopPoint[] = [];
    let found = false;
    for (let i = 0; i < hoops.length; i++) {
      if (hoops[i].timestamp === hoop.timestamp) {
        newHoops.push({...hoop});
        found = true;
      } else {
        newHoops.push(hoops[i]);
      }
    }
    if (!found) newHoops.push(hoop);
    newHoops = newHoops.sort((a, b) => a.timestamp - b.timestamp);
    // Send a post request to save the updated hoops
    if (session?.sessionId) {
      setSessionHoopPointsV4(session.sessionId, newHoops)
      .then(() => toast.success("Updated hoops"))
      .catch(err => {
        console.error(`Failed to update hoops. Error: ${err}`);
        toast.error(`Failed to update hoops.`);
      });
    }
  }
  /**
   * Callback to edit a paint
   */
  const onEditPaints = (paint: AnnotatedPaint) => {
    let newPaints: AnnotatedPaint[] = [];
    let found = false;
    for (let i = 0; i < paints.length; i++) {
      if (paints[i].timestamp === paint.timestamp) {
        newPaints.push({...paint});
        found = true;
      } else {
        newPaints.push(paints[i]);
      }
    }
    if (!found) newPaints.push(paint);
    newPaints = newPaints.sort((a, b) => a.timestamp - b.timestamp);
    setPaints([...newPaints]);
    // Send a post request to save the updated paints
    if (session?.sessionId) {
      setSessionPaintKeypointsV4(session.sessionId, newPaints)
      .then(() => toast.success("Updated paints"))
      .catch(err => {
        console.error(`Failed to update paints. Error: ${err}`);
        toast.error(`Failed to update paints.`);
      });
    }
  }
  /**
   * Callback to edit a net presence
   */
  const onEditHasNets = (hasNet: AnnotatedHasNet) => {
    let newHasNets: AnnotatedHasNet[] = [];
    let found = false;
    for (let i = 0; i < hasNets.length; i++) {
      if (hasNets[i].timestamp === hasNet.timestamp) {
        newHasNets.push({...hasNet});
        found = true;
      } else {
        newHasNets.push(hasNets[i]);
      }
    }
    if (!found) newHasNets.push(hasNet);
    newHasNets = newHasNets.sort((a, b) => a.timestamp - b.timestamp);
    setHasNets([...newHasNets]);
    // Send a post request to save the updated has nets
    if (session?.sessionId) {
      setSessionNetPresencesV4(session.sessionId, newHasNets)
      .then(() => toast.success("Updated net presences"))
      .catch(err => {
        console.error(`Failed to update net presences. Error: ${err}`);
        toast.error(`Failed to update net presences.`);
      });
    }
  }
  /**
   * Build a dataset of tags, shots and highlights together
   */
  const dataset = tags
  .filter(tag => !tag.ignore)
  .map(tag => {
    const shot = shots.find(shot => tag.shotId === shot.shotId)!;
    const highlight = highlights.find(highlight => highlight.shotId === shot.shotId)!;
    const out: TagShotHighlight = { tag, shot, highlight };
    return out;
  })
  .filter(row => {
    // Filter out rebounds where the shot is in
    if (isShotIn(row.shot) && (row.tag.actionId === 2)) return false;
    // Filter out assists where the shot is out
    if (!isShotIn(row.shot) && (row.tag.actionId === 3)) return false;
    // We keep ignored shots to allow admins to change
    return true;
  });
  /**
   * Callback to add a paused timestamp
   */
  const handleOnAddPause = async (timestamp: number) => {
    if (!session) return;
    // Round to int
    timestamp = Math.round(timestamp);
    try {
      // Check if timestamp is already in paused ts
      if (!!session.pausedTs.find(x => x === timestamp)) {
        toast.error(`Pause ${timestamp} already exists`);
        return;
      }
      const success = await addPause(session.sessionId, timestamp);
      if (success) {
        // Add paused timestamp from session (make sure to sort)
        let newPausedTs = [...session.pausedTs, timestamp];
        newPausedTs.sort();
        setSession({...session, pausedTs: [...newPausedTs]});
        // Notify user
        toast.success(`Added paused timestamp`);
      } else {
        toast.error(`Failed to delete paused timestamp`);
      }
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnAddPause`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /**
   * Callback to delete a paused timestamp
   */
  const handleOnDeletePause = async (timestamp: number) => {
    if (!session) return;
    try {
      const success = await deletePause(session.sessionId, timestamp);
      if (success) {
        // Remove paused timestamp from session
        const newPausedTs = session.pausedTs.filter(x => x !== timestamp);
        setSession({...session, pausedTs: [...newPausedTs]});
        // Notify user
        toast.success(`Deleted paused timestamp`);
      } else {
        toast.error(`Failed to delete paused timestamp`);
      }
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnDeletePause`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /** 
   * Hook to update tracker on video timestamp
   */
  useEffect(() => {
    const videoElement = fullVideoRef.current;
    const handleTimeUpdate = () => {
      if (videoElement) setFullVideoCurrentTime(videoElement.currentTime);
    };
    const handleLoadedMetadata = () => {
      if (videoElement) setFullVideoDuration(videoElement.duration);
    };
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    // Unsubscribe on exit
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [isFullVideoRefUsed]);
  /**
   * Callback to delete session entirely
   * @note Updates frontend and backend
   */
  const handleOnDeleteSession = async () => {
    if (!session) return;
    if (!deleteSessionAttempt) {
      setDeleteSessionAttempt(true);
      return;
    }
    try {
      const success = await deleteSession(session.sessionId);
      if (success) {
        navigate("/");  // redirect out of this page since session is deleted
        return;
      } else {
        toast.error(`Failed to delete session ${session.sessionId}`);
      }
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnDeleteSession`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /**
   * Submits a job for video inference
   * @returns 
   */
  const handleOnInferVideoV4 = async () => {
    if (!session) return;
    try {
      await inferVideoV4(session.sessionId);  
      toast.success("Rerunning v4 video inference");
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnInferVideoV4`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /**
   * Submits a job for video inference
   * @param liteMode 
   * @returns 
   */
  const handleOnInferVideoV3 = async (liteMode: boolean) => {
    if (!session) return;
    try {
      await inferVideoV3(session.sessionId, liteMode);  
      toast.success("Rerunning v3 video inference");
    } catch (e) {
      console.error('Error in `SessionViewV3.handleOnInferVideoV3`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  const handleOnInferKeyframesV3 = async () => {
    if (!session) return;
    try {
      await inferKeyframesV3(session.sessionId);
      toast.success("Rerunning v3 keyframe inference");
    } catch (e) {
      console.error('Error in `SessionViewV3.handleOnInferKeyframesV3`: ', e);
      toast.error(`Error: ${e}`);
    }
  }

  /**
   * Submits a job for video compression
   * @param liteMode 
   * @returns 
   */
  const handleOnRequestVideoCompression = async () => {
    if (!session) return;
    try {
      await requestVideoCompression(session.sessionId);
      toast.success("Request video compression");
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnRequestVideoCompression`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /**
   * Respond to a user selection for a new status
   * @param event 
   */
  const handleOnChangeStatus = (event: SelectChangeEvent) => {
    const newStatus = event.target.value as string;
    setNewStatus(newStatus as SessionStatus);
  }
  /**
   * Changes the session status
   * @note Edits frontend and backend
   */
  const handleOnSetStatus = async () => {
    if (!session) return;
    if (!newStatus) return;
    if (session.status === newStatus) {
      toast.error("Cannot set status to same value");
      return;
    }
    try {
      const success = await setSessionStatus(session.sessionId, newStatus);
      if (success) {
        toast.success("Updated session status");
        setSession({...session, status: newStatus as string})
      } else {
        toast.error(`Failed to set session status to "${newStatus}"`);
      }
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnSetStatus`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
   /**
   * Updates a video URL
   * @note Edits frontend and backend
   */
   const handleOnUpdateVideoUrl = async () => {
    if (!session) return;
    if (!newVideoUrl) {
      toast.error("No video URL specified");
      return;
    }
    if (session.videoUrl === newVideoUrl) {
      toast.error("Cannot update video URL to the same URL");
      return;
    }
    try {
      const success = await updateVideoUrl(session.sessionId, newVideoUrl);
      if (success) {
        setSession({...session, videoUrl: newVideoUrl});
        toast.success("Updated session video URL");
      } else {
        toast.error("Failed to set video URL");
      }
    } catch (e) {
      console.error('Error in `SessionViewV4.handleOnUpdateVideoUrl`: ', e);
      toast.error(`Error: ${e}`);
    }
  }
  /**
   * Get color for a session status chip
   * @param status (string): Status of the session
   * @returns (string): Color in hex
   */
  const getChipColor = (status: string) => {
    let backgroundColor: string;
    if (status === SessionStatus.CREATED) {
      backgroundColor = HOOPER_LIGHT_BLUE;
    } else if (status === SessionStatus.ERROR) {
      backgroundColor = HOOPER_RED;
    } else if (status === SessionStatus.PROCESSED) {
      backgroundColor = HOOPER_GREEN;
    } else if (status === SessionStatus.WAITING) {
      backgroundColor = HOOPER_YELLOW;
    } else if (status === SessionStatus.UPLOADED) {
      backgroundColor = "black";
    } else {
      backgroundColor = HOOPER_BLUE;
    }
    return backgroundColor;
  }

  /**
   * Get top page chips
   * @returns (JSX.Element)
   */
  const getTopPageChips = () => {
    if (!session) return;
    return (
      <Grid container direction="row" spacing={2} sx={{ pb: 4 }}> 
        <Grid item>
          <Chip 
            label={session.status} 
            sx={{ backgroundColor: getChipColor(session.status), color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Chip 
            label={`version: ${session.version}`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          {session.visible ? (
            <Chip 
              label={`visible`} 
              sx={{ backgroundColor: HOOPER_GREEN, color: "white", mr: 1 }}
              variant="filled"
            />
          ) : (
            <Chip 
              label={`deleted`} 
              sx={{ backgroundColor: HOOPER_RED, color: "white", mr: 1 }}
              variant="filled"
            />
          )}
        </Grid>
        <Grid item>
          <Chip 
            label={`run version: ${session.runVersion}`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Chip 
            label={`processing progress: ${session.processingProgress}%`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Chip 
            label={`${session.sessionMode}`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Chip 
            label={`${session.sessionType}`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Chip 
            label={`${session.numPlayers} players`} 
            sx={{ backgroundColor: "black", color: "white", mr: 1 }}
            variant="filled"
          />
        </Grid>
        {session.recordingSetup && ( 
          <Grid item>
            <Chip 
              label={`recording: ${session.recordingSetup}`} 
              sx={{ backgroundColor: "black", color: "white", mr: 1 }}
              variant="filled"
            />
          </Grid> 
        )}
        {session.pointSystem && (
          <Grid item>
            <Chip 
              label={`point system: ${session.pointSystem}`} 
              sx={{ backgroundColor: "black", color: "white", mr: 1 }}
              variant="filled"
            />
          </Grid>
        )}
        {session.syncedSessionId && ( 
          <Grid item>
            <Chip 
              label={`synced: ${session.syncedSessionId}`}
              sx={{ backgroundColor: HOOPER_BLUE, color: "white", mr: 1 }}
              variant="filled"
            />
          </Grid>
        )}
      </Grid>
    );
  }
  /**
   * Get top page header
   * @returns (JSX.Element[])
   */
  const getTopPageHeader = () => {
    if (!session) return;
    return [
      <Typography style={{ color: "#758694", fontSize: 20 }} gutterBottom key="top-page-header-1">
        ID: {sessionId}
      </Typography>,
      <Typography style={{ color: "#758694", fontSize: 18 }} key="top-page-header-2">
        Recorded by <Link to={`/user/${session.userId}`}>{session.username}</Link> on {dayjs.unix(session.createdAt).format("MM/D/YY hh:mm:ss A")}. Last updated {dayjs.unix(session.updatedAt).format("MM/D/YY hh:mm:ss A")}.
      </Typography>
    ];
  }
  /**
   * Get pause menu
   * @param session (FBSession): Session to get pause menu for
   * @returns (JSX.Element)
   */
  const getPauseMenu = (session: FBSession) => {
    return (
      <Paper sx={{ px: 4, py: 3, borderBottom: `2px solid ${HOOPER_BLUE}` }}>
        <Box sx={{pb: 2}}>
          <Typography variant="button" sx={{ fontWeight: "bold" }}>Paused Timestamps</Typography>
        </Box>
        {session.pausedTs.length === 0 && (
          <Box sx={{pb: 2}}>
            <Typography style={{ color: "#758694", fontSize: 16 }}>
              No pauses found.
            </Typography>
          </Box>
        )}
        <Box>
          {session.pausedTs.map(ts => (
            <Box sx={{pb: 2, display: "flex", flex: 1, flexDirection: "row", gap: 2}}>
              <TextField fullWidth label="Timestamp" variant="outlined" disabled defaultValue={ts} />
              <Button 
                variant="contained" 
                color="error"
                onClick={() => handleOnDeletePause(ts)}
              >
                <b>Delete</b>
              </Button>
            </Box>
          ))}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pb: 1}}>
          <Typography style={{ color: "#758694", fontSize: 16 }}>
            {Math.round(fullVideoCurrentTime * 10) / 10} / {Math.round(fullVideoDuration * 10 / 10)} seconds ({fullVideoDuration > 0 ? Math.round(fullVideoCurrentTime * 1000 / fullVideoDuration) / 100 : 0}%) 
          </Typography>
        </Box>
        <Box sx={{pb: 1}}>
          <Button 
            fullWidth 
            variant="contained" 
            sx={{ backgroundColor: HOOPER_BLUE }}
            onClick={() => handleOnAddPause(fullVideoCurrentTime)}
          >
            <b>Add paused timestamp</b>
          </Button>
        </Box>
        <Box sx={{ pt: 1}}>
          <Typography style={{ color: "#758694", fontSize: 14 }}>
            Adding a paused timestamp does not automatically update keyframes. If you have added a new paused timestamps, please run keyframe inference. Removing a paused timestamp will delete a keyframe.
          </Typography>
        </Box>
      </Paper>
    )
  }
  /**
   * Get run version selector
   * @returns (JSX.Element)
   */
  const getRunVersionSelector = (session: FBSession) => {
    return (
      <Paper sx={{ px: 4, py: 3, borderBottom: `2px solid ${HOOPER_BLUE}` }}>
        <Box sx={{pb: 2}}>
          <Typography variant="button" sx={{ fontWeight: "bold" }}>Run Version</Typography>
        </Box>
        <Box sx={{pb: 2}}>
          <FormControl fullWidth>
            <InputLabel id="run-version-select-label">Select run version</InputLabel>
            <Select
              labelId="run-version-select-label"
              id="run-version-select"
              value={selectedRunVersion}
              label="Select run version"
              onChange={(e) => setSelectedRunVersion(e.target.value as number)}
            >
              {(session.allRunVersions || []).map(version => (
                <MenuItem key={`menu-item-version-${version}`} value={version}>{version}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{pb: 1}}>
          <Button 
            fullWidth 
            variant="contained" 
            sx={{ backgroundColor: HOOPER_BLUE }}
            onClick={() => {}}
          >
            <b>Switch Run Version</b>
          </Button>
        </Box>
        <Box sx={{ pt: 1}}>
          <Typography style={{ color: "#758694", fontSize: 14 }}>
            Each inference run will increment the run version. This selector lets you toggle between them.
          </Typography>
        </Box>
      </Paper>
    );
  }
  /**
   * Get menu to display video, paused timestamp menu, and run verison selector
   */
  const getVideoMenu = () => {
    if (!session) return;
    return (
      <Grid container columns={12} spacing={8} sx={{ pt: 4 }}>
        <Grid item md={8} sm={12} xs={12}>
          <video ref={fullVideoRef} width="100%" controls style={{ borderRadius: 6 }}>
            <source src={session.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Stack spacing={4}>
            {getPauseMenu(session)}
            {getRunVersionSelector(session)}
          </Stack>              
        </Grid>
      </Grid>
    );
  }
  /**
   * Get processing actions
   * @returns (JSX.Element)
   */
  const getProcessingActions = () => {
    if (!session) return;
    return (
      <Grid container direction="row" spacing={3} columns={12}>
        <Grid item md={3} sm={6} xs={12}>
          <Button 
            fullWidth 
            variant="contained" 
            sx={{ backgroundColor: HOOPER_BLUE }}
            onClick={handleOnInferVideoV4}
          >
            <b>v4 Video Inference</b>
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button 
            fullWidth 
            variant="contained" 
            color="error"
            onClick={handleOnDeleteSession}
          >
            <b>{deleteSessionAttempt ? "Are you sure?" : "Delete Session"}</b>
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button 
            fullWidth 
            variant="outlined" 
            onClick={() => handleOnRequestVideoCompression()}
          >
            <b>Video Compression</b>
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button 
            fullWidth 
            variant="outlined" 
            onClick={() => handleOnInferKeyframesV3()}
          >
            <b>v3 Keyframe Inference</b>
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button 
            fullWidth 
            variant="outlined" 
            onClick={() => handleOnInferVideoV3(false)}
          >
            <b>v3 Video Inference</b>
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}></Grid>
        <Grid item md={3} sm={6} xs={12}></Grid>
        <Grid item md={3} sm={6} xs={12}></Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{pb: 2}}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>Set session status</Typography>
          </Box>
          <Stack 
            direction={"row"}
            spacing={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}  
          >
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="session-status-input">Status</InputLabel>
                <Select
                  labelId="session-status-input"
                  id="session-status-select"
                  value={newStatus}
                  label="Status"
                  onChange={handleOnChangeStatus}
                >
                  <MenuItem value={SessionStatus.CREATED}>
                    <Chip 
                      label={"created"} 
                      sx={{
                        backgroundColor: getChipColor("created"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                  <MenuItem value={SessionStatus.UPLOADED}>
                    <Chip 
                      label={"uploaded"} 
                      sx={{
                        backgroundColor: getChipColor("uploaded"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                  <MenuItem value={SessionStatus.WAITING}>
                    <Chip 
                      label={"waiting"} 
                      sx={{
                        backgroundColor: getChipColor("waiting"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                  <MenuItem value={SessionStatus.PROCESSED}>
                    <Chip 
                      label={"processed"} 
                      sx={{
                        backgroundColor: getChipColor("processed"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                  <MenuItem value={SessionStatus.TAGGED}>
                    <Chip 
                      label={"tagged"} 
                      sx={{
                        backgroundColor: getChipColor("tagged"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                  <MenuItem value={SessionStatus.ERROR}>
                    <Chip 
                      label={"error"} 
                      sx={{
                        backgroundColor: getChipColor("error"),
                        color: "white",
                      }}
                      variant="filled"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button 
                variant="contained" 
                sx={{ backgroundColor: HOOPER_YELLOW }}
                onClick={handleOnSetStatus}
              >
                <b>Update</b>
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{pb: 2}}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>Update Video URL</Typography>
          </Box>
          <Stack 
            direction={"row"}
            spacing={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}  
          >
            <Box sx={{ width: "100%" }}>
              <TextField 
                fullWidth
                id="update-video-url" 
                label="Video URL" 
                variant="outlined" 
                defaultValue={session.videoUrl}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewVideoUrl(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Button 
                variant="contained" 
                sx={{ backgroundColor: HOOPER_YELLOW }}
                onClick={handleOnUpdateVideoUrl}
              >
                <b>Update</b>
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    );
  }
  /**
   * Get actions content
   * @returns (JSX.Element)
   */
  const getActionsContent = () => {
    if (!session) return null;
    return (
      <Paper sx={{ px: 4, pb: 3, pt: 1, borderBottom: `2px solid ${HOOPER_BLUE}` }}>
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography variant="button" sx={{ fontWeight: "bold" }}>Processing Actions</Typography>
        </Box>
        {getProcessingActions()}
      </Paper>
    );
  }
  /**
   * Get banner
   */
  const getVersionBanner = () => {
    return (
      <Box sx={{ borderRadius: 2, backgroundColor: "black", px: 2, py: 1 }}>
        <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 16, p: 1 }}>
          This is the version 4 page. If this session has a version 3, click <a href={`/v3/session/${sessionId}`} style={{ color: "white" }}>here</a>.
        </Typography>
      </Box>
    )
  }
  /**
   * Get row
   * @param key (string)
   * @param value (string)
   * @returns (JSX.Element)
   */
  const getRow = (key: string, value: string) => {
    return (
      <Box 
        sx={{
          display: 'flex',
          flex : 1, 
          flexDirection: "row", 
          justifyContent: "space-between", 
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', pr: 4 }}>
          {key}
        </Typography>
        <Typography sx={{fontSize: 12}}>
          {value}
        </Typography>
      </Box>
    );
  }
  /**
   * Get link row
   * @param key (string)
   * @param value (string)
   * @returns (JSX.Element)
   */
  const getLinkRow = (key: string, value: string) => {
    return (
      <Box 
        sx={{
          display: 'flex',
          flex : 1, 
          flexDirection: "row", 
          justifyContent: "space-between", 
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', pr: 4 }}>
          {key}
        </Typography>
        <Typography sx={{fontSize: 12}}>
          <Link to={value} target="_blank">link</Link>
        </Typography>
      </Box>
    );
  }
  /**
   * Get cluster card
   * @param cluster (FBClusterTagV4)
   * @param curTags (FBTagV4[])
   * @returns (JSX.Element)
   */
  const getClusterCard = (cluster: FBClusterTagV4, curTags: FBTagV4[]) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Paper sx={{ px: 1, py: 1, borderBottom: `2px solid ${HOOPER_BLUE}`}}>
            {getRow("cluster id", cluster.clusterId || "unassigned")}
            {getRow("cluster class", `${cluster.clusterClass}`)}
            {getRow("teamId", `${cluster.teamId || "unassigned"}`)}
            {getRow("userId", `${cluster.userId || "unassigned"}`)}
            {getRow("name", `${cluster.name || "unassigned"}`)}
            {getRow("# shots", `${curTags.filter(x => x.actionId === 1).length}`)}
            {getRow("# rebounds", `${curTags.filter(x => x.actionId === 2).length}`)}
            {getRow("# assists", `${curTags.filter(x => x.actionId === 3).length}`)}
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                mark bystander?
              </Typography>
              <Checkbox 
                checked={cluster.ignore ? !!cluster.ignore: false} 
                sx={{p: 0}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onIgnoreCluster(event.target.checked, cluster);
                }}
              />
            </Box>
          </Paper>
        </Grid>
        {curTags.map(tag => (
          <Grid item key={`tag-${tag.tagId}`}>
            <img src={tag.tagImage} style={{ height: 185, borderRadius: 6 }} alt="tag for the cluster" />
          </Grid>
        ))}
      </Grid>
    );
  }
  /**
   * Get cluster content
   * @returns (JSX.Element)
   */
  const getClusterContent = () => {
    if (!session) return;
    return (
      <Grid container columns={12} spacing={4}>
        {clusters.map(cluster => {
          const curTags = tags.filter(tag => tag.clusterId === cluster.clusterId);
          return (
            <Grid item xs={12} key={`cluster-${cluster.clusterId}`}>
              {getClusterCard(cluster, curTags)}
            </Grid>
          );
        })}
      </Grid>
    );
  }
  /**
   * Get action card
   * @param row (TagShotHighlight)
   * @returns (JSX.Element)
   */
  const getActionCard = (row: TagShotHighlight) => {
    const { tag, shot, highlight } = row;
    return (
      <Grid container spacing={1}>
        <Grid item>
          <img src={tag.tagImage} style={{ height: 100, borderRadius: 6 }} alt="tag for the action" />
        </Grid>
        <Grid item>
          <Paper sx={{ px: 1, py: 1, borderBottom: `2px solid ${HOOPER_BLUE}`}}>
            {getRow("tag id", tag.tagId || "unassigned")}
            {getRow("shot id", shot.shotId || "unassigned")}
            {getRow("highlight id", highlight?.highlightId || "unassigned")}
            {getRow("cluster id", tag.clusterId || "unassigned")}
            {getRow("person id", `${tag.personId}`)}
            {getRow("action id", tag.actionId === 1 ? "shot" : tag.actionId === 2 ? "rebound" : "assist")}
            {getRow("shot span", `${shot.startTs}s - ${shot.endTs}s`)}
            {getRow("keyframe ts", `${shot.keyFrameBallTs}s`)}
            {getRow("scorer ts", `${shot.scorerPossessionFirstTs} - ${shot.scorerPossessionLastTs}s`)}
            {getRow("candidate rebounder ts", shot.rebounderPossessionFirstTs ? `${shot.rebounderPossessionFirstTs}s` : "--")}
            {getRow("candidate assister ts", shot.assisterPossessionLastTs ? `${shot.assisterPossessionLastTs}s` : "--")}
            {getLinkRow("highlight video", highlight?.video || "--")}
            {getRow("highlight length", `${highlight?.length || 0}s`)}
            {highlight && (
              <Box 
                sx={{
                  display: 'flex',
                  flex : 1, 
                  flexDirection: "row", 
                  justifyContent: "space-between", 
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  highlight in feed?
                </Typography>
                <Switch 
                  defaultChecked={highlight.showInFeed} 
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onHideHighlight(event.target.checked, highlight);
                  }}
                />
              </Box>
            )}
            {getRow("predicted shot outcome", `${shot.shotOutcome}`)}
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                annotated shot outcome
              </Typography>
              <Switch 
                defaultChecked={shot.annotatedShotOutcome} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onEditShotOutcome(event.target.checked, shot, highlight);
                }}
              />
            </Box>
            {getRow("predicted shot points", `${shot.shotOutcome ? "2" : "3"}`)}
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                annotated two points
              </Typography>
              <Switch 
                defaultChecked={shot.annotatedIsTwoPoint} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onEditShotPoints(event.target.checked, shot);
                }}
              />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                ignore this action?
              </Typography>
              <Checkbox 
                checked={tag.ignore ? !!tag.ignore: false} 
                sx={{p: 0}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onIgnoreTag(event.target.checked, tag, highlight);
                }}
              />
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex : 1, 
                flexDirection: "row", 
                justifyContent: "space-between", 
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                ignore shot (and all actions)?
              </Typography>
              <Checkbox 
                checked={tag.ignore ? !!tag.ignore: false} 
                sx={{p: 0}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onIgnoreShot(event.target.checked, tag, shot, highlight);
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    )
  }
  /**
   * Get shot content
   * @returns (JSX.Element)
   */
  const getShotContent = () => {
    if (!session) return;
    return (
      <Grid container spacing={4}>
        {dataset.map(row => {
          return (
            <Grid item key={`action-card-${row.tag.tagId}-${row.shot.shotId}`}>
              {getActionCard(row)}
            </Grid>
          );
        })}
      </Grid>
    );
  }
  /**
   * Get keyframe content
   * @returns (JSX.Element)
   */
  const getKeyframeContent = () => {
    if (!session) return null;
    if (!session.videoUrl) return null;
    if (keyframes.length === 0) {
      return (
        <Box>
          <Typography style={{ color: "#758694", fontSize: 16 }}>
            No keyframes found.
          </Typography>
        </Box>
      );
    }
    const gridItems = keyframes.map(keyframe => {
      const hoop = hoops.find(x => x.timestamp === keyframe.ts);
      const paint = paints.find(x => x.timestamp === keyframe.ts);
      const hasNet = hasNets.find(x => x.timestamp === keyframe.ts);
      return (
        <Grid item xl={3} lg={4} sm={6} md={6} xs={12} key={`session-${sessionId}-keyframe-${keyframe.keyframeId}`}>
          <Box>
            <Typography style={{ color: "#758694", fontSize: 12 }}>
              keyframe ID: {keyframe.keyframeId} 
            </Typography>
            <Typography style={{ color: "#758694", fontSize: 12 }}>
              timestamp: {keyframe.ts}s ({keyframe.startTs}-{keyframe.endTs}s)
            </Typography>
            <Typography style={{ color: "#758694", fontSize: 12 }}>
              index: {keyframe.idx}
            </Typography>
            <Box sx={{ display: 'flex', flex : 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
              <Typography sx={{ color: "#758694", fontSize: 12 }}>
                annotated hoop?
              </Typography>
              
              <Checkbox disabled checked={!!hoop} sx={{p: 0, '& .MuiSvgIcon-root': { fontSize: 16 }}} />
            </Box>
            <Box sx={{ display: 'flex', flex : 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
              <Typography sx={{ color: "#758694", fontSize: 12 }}>
                annotated paint?
              </Typography>
              <Checkbox disabled checked={!!paint} sx={{p: 0, '& .MuiSvgIcon-root': { fontSize: 16 }}} />
            </Box>
            <Box sx={{ display: 'flex', flex : 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
              <Typography sx={{ color: "#758694", fontSize: 12 }}>
                annotated net presence?
              </Typography>
              <Checkbox disabled checked={!!hasNet} sx={{p: 0, '& .MuiSvgIcon-root': { fontSize: 16 }}} />
            </Box>
            <Button
              onClick={() => {
                setSelectedKeyframe(keyframe);
                setSelectedHoop(hoop);
                setSelectedPaint(paint);
                setModalOpen(true);
              }}
              sx={{ pt: 1, px: 0, m: 0}}
            >
              <img 
                src={keyframe.path}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </Button>
            <Paper sx={{ px: 2, py: 1, borderBottom: `2px solid ${HOOPER_BLUE}` }}>
              <Box 
                sx={{ 
                  display: 'flex', 
                  flex : 1, 
                  flexDirection: "row", 
                  justifyContent: "space-between", 
                  alignItems: "center", 
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                  set net presence
                </Typography>
                <Checkbox 
                  checked={!!hasNet} 
                  sx={{p: 0 }} 
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newHasNet: AnnotatedHasNet = {
                      hasNet: event.target.checked,
                      timestamp: keyframe.ts,
                    }
                    onEditHasNets(newHasNet);
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>
      );
    });
    return (
      <Grid container direction="row" spacing={6} columns={12}> 
        {gridItems}
      </Grid>
    );
  }
  /**
   * Get content for the session view
   * @returns (JSX.Element)
   */
  const getContent = () => {
    if (!session) return;
    return (
      <Box sx={{ flexGrow: 1, flex: 1, py: 4, px: 8 }}>
        <Box sx={{ pb: 4 }}>
          {getVersionBanner()}
        </Box>
        <Box sx={{ pb: 4 }}>
          {getTopPageChips()}
          {getTopPageHeader()}
          {getVideoMenu()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Action Controls
          </Typography>
          {getActionsContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Keyframes ({(session.pausedTs || []).length + 1})
          </Typography>
          {getKeyframeContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Clusters ({clusters.length})
          </Typography>
          {getClusterContent()}
        </Box>
        <Box sx={{ pb: 4 }}>
          <Typography 
            style={{
              color: "#758694",
              fontSize: 18,
              paddingBottom: 8,
            }}
            gutterBottom
          >
            Shots ({tags.filter(x => x.actionId === 1).length}), Rebounds ({tags.filter(x => x.actionId === 2).length}), and Assists ({tags.filter(x => x.actionId === 3).length})
          </Typography>
          {getShotContent()}
        </Box>
        <KeyframeV4
          visible={modalOpen}
          onClose={() => setModalOpen(false)}
          keyframe={selectedKeyframe}
          hoop={selectedHoop}
          paint={selectedPaint}
          onEditHoop={onEditHoops}
          onEditPaint={onEditPaints}
        />
      </Box>
    );
  }
  return (
    <Box>
      {loading ? <LoadingView loading={loading} /> : getContent()}
    </Box>
  );
}

export default SessionViewV4;