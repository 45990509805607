import React, { useEffect, useState } from "react";
import { 
  Box,
  Typography,
} from "@mui/material";
import {
  fetchUsers,
  fetchSessions,
} from "../shared/services"
import { FBSession, FBUser } from "../shared/types";
import UsersTable from "./UsersTable";
import SessionsTable from "./SessionsTable";
import LoadingView from "./LoadingView";
import { HOOPER_BLUE } from "../shared/constants";

/**
 * Visualizes the most recent sessions and users.
 */
const ControlPanel = () => {
  const [users, setUsers] = useState<FBUser[]>([]);
  const [sessions, setSessions] = useState<FBSession[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handleOnFetchData();
  }, []);

  const handleOnFetchData = async () => {
    try {
      const [
        users,
        sessions,
      ] = await Promise.all([
        fetchUsers(0, 200),
        fetchSessions(0, 500),
      ]);
      if (users) setUsers(users);
      if (sessions) setSessions(sessions);
    } catch (e) {
      console.error('Error in `ControlPanel.handleOnFetchData`: ', e);
    } finally {
      setLoading(false);
    }
  }

  const getContent = () => {
    return (
      <Box sx={{ pb: 4 }}>
        <Box sx={{ mx: 8, my: 4 }}>
          <Typography 
            variant="h6" 
            noWrap 
            component="div" 
            sx={{ 
              fontWeight: 'bold',
              mb: 2,
              color: HOOPER_BLUE,
            }}
          >
            Sessions
          </Typography>
          <SessionsTable sessions={sessions} />
        </Box>
        <Box sx={{ mx: 8, my: 4 }}>
          <Box> 
            <Typography 
              variant="h6" 
              noWrap 
              component="div" 
              sx={{ 
                fontWeight: 'bold',
                mb: 2,
                color: HOOPER_BLUE,
              }}
            >
              Users
            </Typography>
          </Box>
          <UsersTable users={users} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {loading ? <LoadingView loading={loading} /> : getContent()}
    </Box>
  );
}

export default ControlPanel;