import type { 
  AnnotatedHoopPoint, 
  AnnotatedHoopBox,
  AnnotatedPaint, 
  AnnotatedPoint, 
  AnnotatedBox,
  AnnotatedHasNet,
} from "./types";

/**
 * Format annotated points for hoops
 * @param points (number[])
 * @returns (AnnotatedHoopPoint[])
 */
export const formatAnnotatedHoopPoints = (points: number[]): AnnotatedHoopPoint[] => {
  const numPoints = points.length / 3;
  let hoops: AnnotatedHoopPoint[] = [];
  for (let i = 0; i < numPoints; i++) {
    const x = points[i * 3];
    const y = points[i * 3 + 1];
    const timestamp = points[i * 3 + 2];
    const point: AnnotatedPoint = { x: x, y: y };
    const hoop: AnnotatedHoopPoint = {
      timestamp: timestamp,
      point: point,
    }
    hoops.push(hoop);
  }
  return hoops;
}

export const formatAnnotatedNetPresence = (presences: number[]): AnnotatedHasNet[] => {
  const numPoints = presences.length / 2;
  let hasNets: AnnotatedHasNet[] = [];
  for (let i = 0; i < numPoints; i++) {
    const presence = presences[i * 2];
    const timestamp = presences[i * 2 + 1];
    const hasNet: AnnotatedHasNet = {
      hasNet: presence === 1,
      timestamp: timestamp,
    }
    hasNets.push(hasNet);
  }
  return hasNets;
}

/**
 * Format annotated points for hoops
 * @param points (number[])
 * @returns (AnnotatedHoopPoint[])
 */
export const formatAnnotatedHoopBoxes = (boxes: number[]): AnnotatedHoopBox[] => {
  const numPoints = boxes.length / 5;
  let hoops: AnnotatedHoopBox[] = [];
  for (let i = 0; i < numPoints; i++) {
    const x1 = boxes[i * 5];
    const y1 = boxes[i * 5 + 1];
    const x2 = boxes[i * 5 + 2];
    const y2 = boxes[i * 5 + 3];
    const timestamp = boxes[i * 5 + 4];
    const box: AnnotatedBox = { x1, y1, x2, y2 };
    const hoop: AnnotatedHoopBox = {
      timestamp: timestamp,
      box: box,
    }
    hoops.push(hoop);
  }
  return hoops;
}

/**
 * Format annotated paint keypoints
 * @param keypoints (number[])
 * @returns (AnnotatedPaint[])
 */
export const formatAnnotatedPaints = (keypoints: number[]): AnnotatedPaint[] => {
  const numPoints = keypoints.length / 9;
  let paints: AnnotatedPaint[] = [];
  for (let i = 0; i < numPoints; i++) {
    const paintLeft: AnnotatedPoint = {
      x: keypoints[i * 9],
      y: keypoints[i * 9 + 1],
    }
    const paintRight: AnnotatedPoint = {
      x: keypoints[i * 9 + 2],
      y: keypoints[i * 9 + 3],
    }
    const freeThrowLeft: AnnotatedPoint = {
      x: keypoints[i * 9 + 4],
      y: keypoints[i * 9 + 5],
    }
    const freeThrowRight: AnnotatedPoint = {
      x: keypoints[i * 9 + 6],
      y: keypoints[i * 9 + 7],
    }
    const timestamp = keypoints[i * 9 + 8];
    const paint: AnnotatedPaint = {
      paintLeft: paintLeft,
      paintRight: paintRight,
      freeThrowLeft: freeThrowLeft,
      freeThrowRight: freeThrowRight,
      timestamp: timestamp,
    }
    paints.push(paint);
  }
  return paints;
}
