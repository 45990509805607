import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div
        style={{
          height: data.image.height,
          width: data.image.width,
          backgroundImage: `url(${data.image.url})`,
          backgroundRepeat: "no-repeat"
        }}
      />
    </>
  );
});