import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

const Node = styled.div`
  padding: 0px;
  background: ${(props) => props.theme.nodeBg};
  .react-flow__handle {
    background: ${(props) => "#FFFFFF"};
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
`;

export default memo(({ data, selected }) => {
  return (
    <Node selected={selected}>
      <Handle 
        type="target" 
        position={Position.Left}
      />
      <Typography 
        variant="caption" 
        sx={{ 
          fontSize: "20px", 
          color: "white",
          fontWeight: "bold",
          paddingLeft: 3,
        }}
      >
        {data.label}
      </Typography>
    </Node>
  );
});
