import { axiosInstance } from "./axios";
import { SessionStatus } from "./constants";
import type { 
  Keypoint,
  SetSessionStatusRequest,
  SetSessionMissingNetRequestV3,
  AdjustKeypointsV3Request,
  FBUser,
  FBSession,
  FBKeyframe,
  FBKeyframeV4,
  SessionDataV3,
  SessionDataV4,
  DoVideoInferenceRequest,
  IgnoreShotV3Request,
  EditShotStatusV3Request,
  EditShotPointsV3Request,
  EditShotV4Request,
  EditTagV4Request,
  EditHighlightV4Request,
  EditClusterTagV4Request,
  CopyKeypointsV3Request,
  DeletePauseRequest,
  AddPauseRequest,
  UpdateVideoUrlRequest,
  Backboard,
  Hoop,
  Net,
  FBShotV4,
  FBTagV4,
  FBHighlightV4,
  FBClusterTagV4,
  EditNetPresencesRequestV4,
  EditHoopPointsRequestV4,
  EditPaintKeypointsRequestV4,
  AnnotatedHasNet,
  AnnotatedHoopPoint,
  AnnotatedPaint,
} from "./types";

/**
 * Fetches all users from the API
 * @param page (number): Page to start on
 * @param limit (number): Number of users to fetch
 */
export const fetchUsers = async (page: number, limit: number): Promise<FBUser[]> => {
  return axiosInstance
  .get('/admin/users', { params: { page: page, limit: limit }})
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchUsers`", err));
}

/**
 * Fetch a single user from that API
 * @param userId (string): ID of the user to fetch
 */
export const fetchUser = async (userId: string): Promise<FBUser> => {
  return axiosInstance
  .get(`/admin/users/${userId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchUser`", err));
}

/**
 * Fetches all sessions from the API
 * @param page (number): Page to start on
 * @param limit (number): Number of sessions to fetch
 */
export const fetchSessions = async (page: number, limit: number): Promise<FBSession[]> => {
  return axiosInstance
  .get('/admin/sessions', { params: { page: page, limit: limit }})
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessions`", err));
}

/**
 * Fetch session from the API
 * @param sessionId (string): ID of the session to fetch
 */
export const fetchSessionV3 = async (sessionId: string): Promise<SessionDataV3> => {
  return axiosInstance
  .get(`/admin/sessions/v3/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionV3`", err));
}

/**
 * Fetch session from the API
 * @param sessionId (string): ID of the session to fetch
 */
export const fetchSessionV4 = async (sessionId: string, overrideRunVersion?: number): Promise<SessionDataV4> => {
  return axiosInstance
  .get(`/admin/sessions/v4/${sessionId}`, { params: { override_run_version: overrideRunVersion }})
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionV4`", err));
}

/**
 * Fetches all sessions belonging to a user from the API
 * @param userId (string): ID of the user
 * @param page (number): Page to start on
 * @param limit (number): Number of sessions to fetch
 */
export const fetchUserSessions = async (
  userId: string,
  page: number, 
  limit: number,
): Promise<FBSession[]> => {
  return axiosInstance
  .get(`/admin/sessions/users/${userId}`, { params: { page: page, limit: limit }})
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessions`", err));
}

/**
 * Deletes a session
 * @param id (string): ID of the session to delete
 */
export const deleteSession = async (id: string): Promise<boolean> => {
  return axiosInstance
  .delete(`/admin/session/${id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `deleteSession`", err));
}

/**
 * Adjusts keypoints for session
 */
export const adjustKeypointsV3 = async (
  keyframeId: string, 
  keypoints: Keypoint[],
  hoop?: Hoop,
  backboard?: Backboard,
  net?: Net,
): Promise<boolean> => {
  const body: AdjustKeypointsV3Request = {
    keyframeId: keyframeId,
    keypoints: keypoints,
    hoop: hoop,
    backboard: backboard,
    net: net,
  };
  return axiosInstance
  .post(`/admin/session/v3/keyframe/keypoints`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `adjustKeypoints`", err));
}

export const fetchKeyframesV3 = async (id: string): Promise<{ids: string[], keyframes: FBKeyframe[]}> => {
  return axiosInstance
  .get(`/admin/session/v3/keyframes/${id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchKeyframes`", err));
}

export const fetchKeyframeV3 = async (id: string): Promise<FBKeyframe> => {
  return axiosInstance
  .get(`/admin/session/v3/keyframe/${id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchKeyframe`", err));
}

export const fetchKeyframeV4 = async (keyframeId: string): Promise<FBKeyframeV4> => {
  return axiosInstance
  .get(`/admin/session/v4/keyframe/${keyframeId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchKeyframeV4`", err));
}

export const deleteKeyframeV3 = async (id: string): Promise<boolean> => {
  return axiosInstance
  .post(`/admin/session/v3/keyframe/${id}/delete`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `deleteKeyframe`", err));
}

export const resetKeyframesV3 = async (id: string): Promise<boolean> => {
  return axiosInstance
  .post(`/admin/session/keyframes/reset/${id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `resetKeyframes`", err));
}

export const inferKeyframesV3 = async (sessionId: string): Promise<boolean> => {
  return axiosInstance
  .post(`/admin/session/v3/keyframes/infer/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `inferKeyframesV3`", err));
}

export const inferVideoV3 = async (sessionId: string, liteMode: boolean) => {
  const body: DoVideoInferenceRequest = {
    minChunkLength: 10,            // 10sec min
    maxLength: 3600,               // 40min max
    batchSize: 64,                 // batch 16 takes up 12GB RAM; A40 has 48GB
    sampleRate: 2,                 // sample rate of 2
    liteMode: liteMode,            // lite mode
  }
  return axiosInstance
  .post(`/admin/session/v3/infer/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `doVideoInferenceV3`", err));
}

export const inferVideoV4 = async (sessionId: string) => {
  return axiosInstance
  .post(`/admin/session/v4/infer/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `doVideoInferenceV4`", err));
}

/**
 * Request video compression
 */
export const requestVideoCompression = async (id: string) => {
  return axiosInstance
  .post(`/admin/session/request/compress/${id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `doVideoCompression`", err));
}

/** 
 * Set session state
 */
export const setSessionStatus = async (id: string, status: SessionStatus): Promise<boolean> => {
  const body: SetSessionStatusRequest = { status: status.toString() };
  return axiosInstance
  .post(`/admin/session/status/${id}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `setSessionStatus`", err));
}

/** 
 * Set session missing net
 */
export const setSessionMissingNetV3 = async (id: string, missingNet: boolean): Promise<boolean> => {
  const body: SetSessionMissingNetRequestV3 = { missingNet: missingNet };
  return axiosInstance
  .post(`/admin/session/v3/net/${id}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `setSessionMissingNet`", err));
}

/** 
 * Set session net presences
 */
export const setSessionNetPresencesV4 = async (sessionId: string, hasNets: AnnotatedHasNet[]): Promise<boolean> => {
  const body: EditNetPresencesRequestV4 = { hasNets: hasNets };
  return axiosInstance
  .post(`/admin/session/v4/net/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `setSessionNetPresencesV4`", err));
}

/** 
 * Set session hoop points
 */
export const setSessionHoopPointsV4 = async (sessionId: string, hoops: AnnotatedHoopPoint[]): Promise<boolean> => {
  const body: EditHoopPointsRequestV4 = { hoops: hoops };
  return axiosInstance
  .post(`/admin/session/v4/hoop/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `setSessionHoopPointsV4`", err));
}

/** 
 * Set session net presences
 */
export const setSessionPaintKeypointsV4 = async (sessionId: string, paints: AnnotatedPaint[]): Promise<boolean> => {
  const body: EditPaintKeypointsRequestV4 = { paints: paints };
  return axiosInstance
  .post(`/admin/session/v4/paint/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `setSessionPaintKeypointsV4`", err));
}

/**
 * Copy keypoints from another keyframe
 */
export const copyKeypointsV3 = async (targetKeyframeId: string, sourceKeyframeId: string): Promise<boolean> => {
  const body: CopyKeypointsV3Request = { keyframeId: sourceKeyframeId };
  return axiosInstance
  .post(`/admin/session/v3/keyframe/copy/${targetKeyframeId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `copyKeypoints`", err));
}

/**
 * Delete paused timestamp (and keyframe)
 */
export const deletePause = async (sessionId: string, timestamp: number, keyframeId?: string): Promise<boolean> => {
  const body: DeletePauseRequest = {
    timestamp: timestamp,
    keyframeId: keyframeId,
  };
  return axiosInstance
  .post(`/admin/pauses/delete/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `deletePause`", err));
}

/** 
 * Add a pause timestamp (and sort)
 */
export const addPause = async (sessionId: string, timestamp: number): Promise<boolean> => {
  const body: AddPauseRequest = { timestamp: timestamp };
  return axiosInstance
  .post(`/admin/pauses/add/${sessionId}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `addPause`", err));
}

/**
 * Update video URL for session
 */
export const updateVideoUrl = async (id: string, videoUrl: string): Promise<boolean> => {
  const body: UpdateVideoUrlRequest = { videoUrl: videoUrl };
  return axiosInstance
  .post(`/admin/session/video/update/${id}`, body)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `updateVideoUrl`", err));
}

/**
 * Update session team names
 * @param body (IgnoreShotV3Request)
 * @returns (string)
 */
export const ignoreShotV3 = async (body: IgnoreShotV3Request): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v3/shots/ignore`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `ignoreShot`:", err));
}

/**
 * Update session shot status
 * @param body (EditShotStatusV3Request)
 * @returns (string)
 */
export const editShotStatusV3 = async (body: EditShotStatusV3Request): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v3/shots/edit/status`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `editShotStatusV3`:", err));
}

/**
 * Update session shot points
 * @param body (EditShotPointsV3Request)
 * @returns (string)
 */
export const editShotPointsV3 = async (body: EditShotPointsV3Request): Promise<boolean> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v3/shots/edit/points`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `editShotPointsV3`:", err));
}

/**
 * Edit a shot for a session
 * @param body (EditShotV4Request)
 * @returns (FBShotV4)
 */
export const editShotV4 = async (body: EditShotV4Request): Promise<FBShotV4> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v4/shots/edit`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `editShotV4`:", err));
}

/**
 * Edit a tag for a session
 * @param body (EditTagV4Request)
 * @returns (FBTagV4)
 */
export const editTagV4 = async (body: EditTagV4Request): Promise<FBTagV4> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v4/tags/edit`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `EditTagV4Request`:", err);
  });
}

/**
 * Update highlight properties
 * Only for v4
 */
export const editHighlightV4 = async (body: EditHighlightV4Request): Promise<FBHighlightV4> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v4/highlights/edit`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `editHighlightV4`:", err);
  });
}

export const editClusterTagV4 = async (body: EditClusterTagV4Request): Promise<FBClusterTagV4> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`/admin/session/v4/clusters/edit`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `editClusterTagV4`:", err);
  });
}