import React, { useEffect, useState, useCallback } from 'react';
import {
  NodeResizer,
  useUpdateNodeInternals,
} from 'reactflow';

import styles from './style.module.css';

export default function ResizableNode({ id, data }) {
  const [width, setWidth] = useState(data.width || 50);
  const [height, setHeight] = useState(data.height || 50);
  // const [position, setPosition] = useState(position || { x: 0, y: 0 });
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(id); // Initial update to ensure correct positioning
  }, [id, updateNodeInternals]);

  const onResize = useCallback(
    (event, { width, height, x, y }) => {
      setWidth(width);
      setHeight(height);
      // Force update the node internals
      updateNodeInternals(id);
    },
    [id, updateNodeInternals]
  );

  return (
    <>
      <div
        style={{
          border: `2px solid ${data.color}`,
          width: width,
          height: height,
        }}
        className={styles.node}
      >
        <NodeResizer 
          isVisible={data.resizable} 
          onResize={onResize}
        />
        <div 
          style={{
            color: data.color,
            fontSize: 10,
          }}
        >
          {data?.label}
        </div>
      </div>
    </>
  );
}
