import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import type { FBUser } from "../shared/types";
import { 
  Avatar,
  Chip,
  Paper, 
} from "@mui/material";
import { 
  DataGrid, 
  GridRowsProp, 
  GridColDef, 
  GridToolbar, 
} from "@mui/x-data-grid";
import { HOOPER_BLUE } from "../shared/constants";

type InputProps = {
  users: FBUser[];
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));

const UsersTable = ({ users }: InputProps) => {
  const rows: GridRowsProp = [
    ...users.map((user) => ({
      id: user.userId,
      userId: user.userId,
      picture: user.profilePicture,
      username: user.username,
      email: user.email,
      subscriptionType: user.subscriptionType,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    })),
  ];

  const columns: GridColDef[] = [
    { 
      field: 'userId', 
      headerClassName: 'super-app-theme--header',
      headerName: 'User ID', 
      width: 300,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return <Link to={`/user/${params.value}`}>{params.value}</Link>;
      }
    },
    {
      field: 'picture',
      headerName: 'Avatar',
      width: 100,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          return (
            <Avatar 
              src={params.value}
              sx={{ width: 36, height: 36 }}
            />
          );
        } else {
          return (
            <Avatar 
              sx={{ 
                bgcolor: HOOPER_BLUE,
                width: 36, 
                height: 36,
              }} 
            />
          )
        } 
      }
    },
    { 
      field: 'username', 
      headerName: 'Username', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return params.value || "--";
      }
    },
    { 
      field: 'email', 
      headerName: 'Email', 
      width: 200,
      headerAlign: 'center',
      align: "center",
    },
    { 
      field: 'subscriptionType',
      headerName: 'Subscription', 
      width: 150, 
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return (
          <Chip 
            label={params.value} 
            sx={{
              backgroundColor: HOOPER_BLUE,
              color: "white",
            }}
            variant="filled"
          />
        )
      }
    },
    { 
      field: 'createdAt', 
      headerName: 'Created At', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return dayjs(params.value * 1000).format("MM/DD/YY");
      }
    },
    { 
      field: 'updatedAt', 
      headerName: 'Updated At', 
      width: 150,
      headerAlign: 'center',
      align: "center",
      renderCell: (params) => {
        return dayjs(params.value * 1000).format("MM/DD/YY");
      }
    },
  ];

  return (
    <Paper>
      <div style={{ height: 1000, width: '100%' }}>
        <StyledDataGrid rows={rows} columns={columns} slots={{ toolbar: GridToolbar }}  />
      </div>
    </Paper>
  );
}

export default UsersTable;