import React, { useEffect } from "react";
import ReactFlow, { MiniMap, Controls, Background, useNodesState } from "reactflow";
import type { Node } from "reactflow";
import { Box, Button } from "@mui/material";
import 'reactflow/dist/base.css';
import KeypointNode from "./KeypointNode";
import ResizableNode from "./ResizableNode";
import ImageNode from "./ImageNode";
import Modal from '@mui/material/Modal';
import type { AnnotatedHoopPoint, AnnotatedPaint, AnnotatedPoint, FBKeyframeV4 } from "../shared/types";
import { HOOPER_YELLOW } from "../shared/constants";

const nodeTypes = {
  keypoint: KeypointNode,
  image: ImageNode,
  box: ResizableNode,
}

type InputProps = {
  visible: boolean;
  onClose: () => void;
  keyframe?: FBKeyframeV4;
  hoop?: AnnotatedHoopPoint;
  paint?: AnnotatedPaint;
  onEditHoop: (hoop: AnnotatedHoopPoint) => void;
  onEditPaint: (paint: AnnotatedPaint) => void;
}

/**
 * KeyframeV4 - UI to visualize keypoints and move them around
 */
const KeyframeV4 = ({
  visible,
  onClose,
  keyframe,
  hoop,
  paint,
  onEditHoop,
  onEditPaint,
}: InputProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  /**
   * Callback on loading
   */
  useEffect(() => {
    if (!keyframe) return;
    try {
      setNodes(getInitialNodes(keyframe));
    } catch (err) {
      console.error("Error in setting nodes: ", err);
    }
    return () => setNodes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyframe]);
  /**
   * Helper function
   */
  const getCenter = (
    nodeX: number, 
    nodeY: number,  
  ): AnnotatedPoint => {
    // Compute the center of the handle
    const handleCenterX = nodeX;
    const handleCenterY = nodeY;
    return { x: handleCenterX, y: handleCenterY };
  }
  /**
   * Callback on saving
   */
  const handleOnSave = async () => {
    if (!keyframe) return;
    const hoopNode = nodes.find(node => node.id === '1')!;
    const paintLeftNode = nodes.find(node => node.id === '2')!;
    const paintRightNode = nodes.find(node => node.id === '3')!;
    const freeThrowLeftNode = nodes.find(node => node.id === '4')!;
    const freeThrowRightNode = nodes.find(node => node.id === '5')!;
    const newHoop: AnnotatedHoopPoint = {
      point: getCenter(hoopNode.position.x, hoopNode.position.y),
      timestamp: keyframe.ts,
    }
    const newPaint: AnnotatedPaint = {
      paintLeft: getCenter(paintLeftNode.position.x, paintLeftNode.position.y),
      paintRight: getCenter(paintRightNode.position.x, paintRightNode.position.y),
      freeThrowLeft: getCenter(freeThrowLeftNode.position.x, freeThrowLeftNode.position.y),
      freeThrowRight: getCenter(freeThrowRightNode.position.x, freeThrowRightNode.position.y),
      timestamp: keyframe.ts,
    }
    onEditHoop(newHoop);
    onEditPaint(newPaint);
  }
  /**
   * Get nodes to render
   */
  const getInitialNodes = (keyframe: FBKeyframeV4): Node[] => {
    const imageNode = {
      id: '0',
      type: 'image',
      position: { x: 0, y: 0 },
      data: {
        image: {
          url: keyframe.path,
          height: keyframe.height,
          width: keyframe.width,
        }
      },
      draggable: false,
      style: { pointerEvents: 'none' as 'none', zIndex: -100000 } // Inline styles
    };
    const hoopNode = {
      id: '1',
      type: 'keypoint',
      position: { 
        x: hoop ? hoop.point.x : keyframe.width / 2,
        y: hoop ? hoop.point.y : 1 * keyframe.height / 3,
      },
      data: { label: 'hoop' },
    }
    const paintLeftNode = {
      id: '2',
      type: 'keypoint',
      position: { 
        x: paint ? paint.paintLeft.x : 1 * keyframe.width / 3,
        y: paint ? paint.paintLeft.y : keyframe.height / 3,
      },
      data: { label: 'paint-left' },
    }
    const paintRightNode = {
      id: '3',
      type: 'keypoint',
      position: { 
        x: paint ? paint.paintRight.x : 2 * keyframe.width / 3,
        y: paint ? paint.paintRight.y : keyframe.height / 3,
      },
      data: { label: 'paint-right' },
    }
    const freeThrowLeftNode = {
      id: '4',
      type: 'keypoint',
      position: { 
        x: paint ? paint.freeThrowLeft.x : keyframe.width / 3,
        y: paint ? paint.freeThrowLeft.y : 2 * keyframe.height / 3,
      },
      data: { label: 'free-throw-left' },
    }
    const freeThrowRightNode = {
      id: '5',
      type: 'keypoint',
      position: { 
        x: paint ? paint.freeThrowRight.x : 2 * keyframe.width / 3,
        y: paint ? paint.freeThrowRight.y : 2 * keyframe.height / 3,
      },
      data: { label: 'free-throw-right' },
    }
    return [
      hoopNode, paintLeftNode, paintRightNode, freeThrowLeftNode, freeThrowRightNode, imageNode,
    ];
  }
  /**
   * Main function to render
   */
  const getContent = () => {
    return (
      <div style={{ width: '100vw', height: '90vh' }}>
        <ReactFlow 
          nodes={nodes} 
          onNodesChange={onNodesChange}
          nodeTypes={nodeTypes}
          minZoom={0.5} // Adjust minimum zoom level
          maxZoom={2}   // Adjust maximum zoom level
          zoomOnScroll={true} // Enable zooming with scroll
          zoomOnPinch={true}  // Enable pinch to zoom
          panOnScroll={true}  // Enable panning with scroll
          panOnDrag={true}    // Enable panning with drag
        >
          <Controls />
          <MiniMap />
          <Background gap={12} size={1} />
        </ReactFlow>
        <Box sx={{ position: "absolute", bottom: 300, right: 15 }}>
          <Button 
            variant="contained" 
            sx={{ background: HOOPER_YELLOW, width: 200 }}
            onClick={() => handleOnSave()}
          >
            <b>Save</b>
          </Button>
        </Box>
      </div>
    );
  };
  return (
    <Modal open={visible} onClose={onClose}>
      {getContent()}
    </Modal>
  );
}

export default KeyframeV4;