import React, { useState } from "react";
import { sha256 } from 'js-sha256';
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { 
  HOOPER_BLUE, 
  ADMIN_USERNAME_HASH,
  ADMIN_PASSWORD_HASH,
  IS_AUTH_KEY,
} from "../shared/constants";
import toast from 'react-hot-toast';

const LoginView = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const notifyError = (message: string) => toast.error(message, {
    position: 'top-center',
  });

  const handleOnLogin = () => {
    const success = (
      (sha256(username) === ADMIN_USERNAME_HASH) &&
      (sha256(password) === ADMIN_PASSWORD_HASH)
    );
    if (success) {
      // Save to local storage
      // @note this is not very secure but it's good enough for now
      localStorage.setItem(IS_AUTH_KEY, "1");

      // Navigate to admin portal
      navigate('/');
    } else {
      notifyError("Incorrect username or password");
    }
  }
  return (
    <Box 
      sx={{ 
        height: "100vh",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper 
        sx={{
          width: 500,
          p: 4,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography 
              variant="h6" 
              sx={{ color: HOOPER_BLUE, fontWeight: "bold" }}
            >
              Admin Portal
            </Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <TextField 
              fullWidth
              id="username-textfield" 
              label="Username"  
              InputProps={{ sx: { borderRadius: 40 } }}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <TextField 
              fullWidth
              id="password-textfield" 
              label="Password" 
              InputProps={{ sx: { borderRadius: 40 } }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box sx={{ pt: 3 }}>
            <Button 
              fullWidth 
              variant="contained"
              sx={{
                backgroundColor: HOOPER_BLUE,
                borderRadius: 40,
              }}
              onClick={handleOnLogin}
            >
              <b>Login</b>
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default LoginView;