import { Navigate } from "react-router-dom";
import { IS_AUTH_KEY } from "../shared/constants";

/**
 * ProtectedRoute - A route that requires authentication
 */
const ProtectedRoute = ({ redirectPath, children }) => {
  const isAuthenticated = localStorage.getItem(IS_AUTH_KEY);
  if (isAuthenticated !== "1") {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default ProtectedRoute;
