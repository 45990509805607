import React from "react";
import { 
  Backdrop,
  CircularProgress,
} from "@mui/material";

type InputProps = {
  loading: boolean;
}

const LoadingView = ({loading}: InputProps) => {
  return (
    <Backdrop
      sx={{ 
        color: 'black', 
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingView;